// src/routing/route.config.tsx
import {
  createEntityRoutes,
  DashboardB2BPage,
  ForgotPasswordPage,
  OnboardingPage,
  ProjectViewPage,
  ResetPasswordPage,
  RouteConfig,
} from "@fraktion/core-frontend";
import { ProjectsOverviewPage, LoginPage } from "@fraktion/core-frontend";

export const routes: RouteConfig[] = [
  {
    path: "/onboarding",
    component: OnboardingPage,
    label: "Onboarding",
    translationKey: "nav.onboarding",
    icon: "onboarding",
    showInNav: false,
  },
  {
    path: "/dashboard",
    component: DashboardB2BPage,
    label: "Dashboard",
    translationKey: "nav.dashboard",
    icon: "home",
    showInNav: true,
  },
  ...createEntityRoutes("users", {
    translationKey: "nav.users",
    icon: "users",
    showInNav: true,
    defaultViewMode: "table",
    showViewModeSwitch: false,
    generateRoutes: {
      list: true,
      create: true,
      update: true,
    },
  }),
  {
    path: "/projects/:id/view",
    component: ProjectViewPage,
    label: "Project View",
    translationKey: "nav.projectView",
    icon: "projects",
  },
  {
    path: "/projects-overview",
    component: ProjectsOverviewPage,
    label: "Projets (Tous)",
    translationKey: "nav.projectsOverview",
    icon: "projects",
    showInNav: true,
  },
  ...createEntityRoutes("projects", {
    translationKey: "nav.projects",
    icon: "projects",
    showInNav: true,
    defaultViewMode: "table",
    showViewModeSwitch: false,
    generateRoutes: {
      list: true,
      create: true,
      update: true,
    },
  }),
  ...createEntityRoutes("campaigns", {
    translationKey: "nav.campaigns",
    icon: "campaigns",
    showInNav: true,
    defaultViewMode: "table",
    showViewModeSwitch: false,
    generateRoutes: {
      list: true,
      create: true,
      update: true,
    },
  }),
  ...createEntityRoutes("orders", {
    translationKey: "nav.investmentsOverview",
    icon: "list",
    showInNav: true,
    defaultViewMode: "table",
    showViewModeSwitch: false,
    listPath: "/investissements-overview",
    generateRoutes: {
      list: true,
    },
  }),
  {
    path: "/auth/login",
    component: LoginPage,
    label: "Login",
    translationKey: "nav.login",
    icon: "login",
    showInNav: false,
    public: true,
  },
  {
    path: "/auth/forgot-password",
    component: ForgotPasswordPage,
    label: "Forgot Password",
    translationKey: "nav.forgotPassword",
    icon: "password",
    showInNav: false,
    public: true,
  },
  {
    path: "/auth/reset-password",
    component: ResetPasswordPage,
    label: "Reset Password",
    translationKey: "nav.resetPassword",
    icon: "password",
    showInNav: false,
    public: true,
  },
];
